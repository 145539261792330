<template>
	<div id="rootVM" v-cloak style="display: flex; flex-direction: row">
		<div style="display: flex; flex-direction: column; width: 83vw">
			<div style="display: flex; flex-direction: row">
				<div class="boxBorder" style="width: 440px; height: 308px">
					<div id="weatherInfo">
						<weather-info :loginUserSiteNm="loginUserSiteNm"></weather-info>
					</div>
				</div>
				<div class="boxBorder" style="width: 630px; height: 308px">
					<div class="titleBox">
						<div style="display: flex; justify-content: space-between">
							<div style="display: flex; align-items: center">
								<div class="circle"></div>
								최근 위험 발생
							</div>
							<div type="button" class="btn" style="border-radius: 5px; background: #2367b2; margin-right: 10px; float: right">
								<a style="color: rgb(255, 255, 255)" href="/download/사용자매뉴얼_안전관리자용.pdf" download>매뉴얼 다운로드</a>
							</div>
						</div>
					</div>
					<div class="contentBox" style="height: 260px; padding: 10px">
						<div v-if="dangerOccurRealtime != null">
							<div v-if="dangerOccurRealtime.type === 'nonEvent'">
								<div style="display: flex">
									<div><img src="/images/icon-no-result.png" no-result /></div>
									<div style="padding-top: 90px">
										<span>{{ nonDangerOccurResultStr }}</span>
									</div>
								</div>
							</div>
							<div v-if="dangerOccurRealtime.type === 'event'">
								<div style="cursor: pointer; width: 310px; text-align: center; float: left" @click="curEventFrames">
									<img :src="dangerOccurRealtimeImgSrc" style="width: auto; height: auto; max-width: 310px; max-height: 240px" />
								</div>
								<div style="width: 290px; height: 270px; margin-left: 10px; float: left; overflow: hidden">
									<div class="rt-dvsn">
										{{ dangerOccurRealtime.videoDangerActnDvsnNm }}
									</div>
									<div class="rt-time">{{ dangerOccurRealtime.dangerOccurDtm }}</div>
									<div class="rt-row">
										<div class="rt-row-tt">
											<span>현장명</span>
										</div>
										<div class="rt-row-ct">
											<span>{{ dangerOccurRealtime.siteNm }}</span>
										</div>
									</div>
									<div class="rt-row">
										<div class="rt-row-tt">
											<span>CCTV</span>
										</div>
										<div class="rt-row-ct">
											<span>[{{ dangerOccurRealtime.cctvNm }}]</span>
											<span>{{ dangerOccurRealtime.cctvInstallPlaceCont }}</span>
										</div>
									</div>
									<div class="rt-row">
										<div class="rt-row-tt">
											<span>조치내용</span>
										</div>
										<div class="rt-row-ct">
											<span>[{{ dangerOccurRealtime.objectNm }}]</span>
											<span>{{ dangerOccurRealtime.targetNm }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div notice>
				<div class="boxBorder" style="width: 1090px; height: 50px">
					<div class="nt-guide" style="display: flex; justify-content: center; align-items: center">
						<span style="display: inline-block">최신 공지사항</span>
					</div>
					<div style="width: 80%">
						<div v-for="(notice, index) in noticeInfo" :key="index" @click="showNoticeDetail(index)" style="width: 100%">
							<span style="display: inline-block; width: 50%; text-decoration: underline">{{ notice.noticeTitle }}</span>
							<span style="display: inline-block; width: 25%; text-align: right" v-if="notice.oriFileNm != ''">
								<i class="icon-attachment"></i>
							</span>
							<span style="display: inline-block; width: 25%; text-align: right" v-if="notice.oriFileNm == ''"></span>
							<span style="display: inline-block; width: 12%; text-align: center">{{ notice.regUser }}</span>
							<span style="display: inline-block; width: 12%; text-align: center">{{ notice.regDtm }}</span>
						</div>
					</div>
				</div>
			</div>
			<div process>
				<div
					class="boxBorder"
					id="bd"
					style="cursor: pointer; width: 1090px; height: 123px; padding: 0px; font-size: 15px; display: flex; background-color: white"
				>
					<div @click="clickedRedirectionDangerOccur" style="height: 90px">
						<div class="process-info-title">
							<img class="process-img" src="/images/renew-main-위험발생.png" style="width: 18px" />
							위험발생
						</div>
						<div class="process-info">
							<span class="process-count" style="color: #ef5861">{{ currentWarnWorkDangerOccur.dangerOccurCnt }}</span>
							<span class="process-suffix">건</span>
						</div>
					</div>
					<div @click="clickedRedirectionWarnWorkOccur">
						<div class="process-info-title">
							<img class="process-img" src="/images/renew-main-주의발생.png" />
							주의발생
						</div>
						<div class="process-info">
							<span class="process-count" style="color: #f2ca47">{{ currentWarnWorkDangerOccur.warnWorkOccurCnt }}</span>
							<span class="process-suffix">건</span>
						</div>
					</div>
					<div @click="clickedRedirectionDangerOccurTakingAction">
						<div class="process-info-title">
							<img class="process-img" src="/images/renew-main-조치중.png" />
							조치 중
						</div>
						<div class="process-info">
							<span class="process-count" style="color: #a0dbe9">{{ currentWarnWorkDangerOccur.takingActionCnt }}</span>
							<span class="process-suffix">건</span>
						</div>
					</div>
					<div @click="clickedCnt('req')">
						<div class="process-info-title">
							<img class="process-img" src="/images/renew-main-조치대기.png" />
							조치대기
						</div>
						<div class="process-info">
							<span class="process-count" style="color: #0b5aa8">{{ currentAccidentPreventItem.reqCnt }}</span>
							<span class="process-suffix">건</span>
						</div>
					</div>
					<div @click="clickedCnt('review')">
						<div class="process-info-title">
							<img class="process-img" src="/images/renew-main-검토대기.png" />
							검토대기
						</div>
						<div class="process-info">
							<span class="process-count" style="color: #a0dbe9">{{ currentAccidentPreventItem.reviewCnt }}</span>
							<span class="process-suffix">건</span>
						</div>
					</div>
					<div @click="clickedCnt('actn')">
						<div class="process-info-title">
							<img class="process-img" src="/images/renew-main-완료.png" />
							완료
						</div>
						<div class="process-info">
							<span class="process-count" style="color: #3c906c">{{ currentAccidentPreventItem.actnCnt }}</span>
							<span class="process-suffix">건</span>
						</div>
					</div>
					<div @click="clickedCnt('today')">
						<div class="process-info-title">
							<img class="process-img" src="/images/renew-main-금일마감.png" />
							금일 마감
						</div>
						<div class="process-info">
							<span class="process-count" style="color: #f2ca47">{{ currentAccidentPreventItem.todayDelayCnt }}</span>
							<span class="process-suffix">건</span>
						</div>
					</div>
				</div>
			</div>
			<div style="display: flex; flex-direction: row">
				<div
					class="boxBorder"
					style="display: flex; flex-direction: column; padding-top: 0px; padding-bottom: 0px; width: 440px; height: 310px; background-color: white"
				>
					<div class="titleBox" style="display: flex; align-items: center; padding-left: 0; padding-top: 0">
						<div style="display: flex; align-items: center">
							<div class="circle" style="margin-left: 10px"></div>
							<div style="font-weight: bold; height: 48px; padding-top: 10px">현황</div>
						</div>
						<div class="btn-group" style="margin-left: auto; padding: 0 10px">
							<button
								type="button"
								class="btn"
								style="width: 50px; height: 28px; padding: 0px"
								v-bind:class="chartClickClass[0]"
								@click="loadDayChart"
							>
								일
							</button>
							<button
								type="button"
								class="btn"
								style="width: 50px; height: 28px; padding: 0px"
								v-bind:class="chartClickClass[1]"
								@click="loadWeekChart"
							>
								주
							</button>
							<button
								type="button"
								class="btn"
								style="width: 50px; height: 28px; padding: 0px"
								v-bind:class="chartClickClass[2]"
								@click="loadMonthChart"
							>
								월
							</button>
						</div>
					</div>
					<div style="flex: 5; margin: 3px auto 0 auto; width: 420px; padding: 0">
						<div id="lineChart" style="height: 100%"></div>
					</div>
				</div>
				<div class="boxBorder" style="width: 630px; height: 310px">
					<div class="titleBox">
						<div style="display: flex; align-items: center">
							<div class="circle"></div>
							위험 보고
						</div>
					</div>
					<div class="contentBox">
						<table danger-occur-table>
							<tr>
								<th style="width: 30%">{{ dangerOccurTable.siteCctvNm }}</th>
								<th style="width: 16%">{{ dangerOccurTable.dangerOccurDtm }}</th>
								<th style="width: 17%">{{ dangerOccurTable.objectNm }}</th>
								<th style="width: 17%">{{ dangerOccurTable.targetNm }}</th>
								<th style="width: 20%">{{ dangerOccurTable.videoDangerActnDvsnNm }}</th>
							</tr>
							<tr v-for="(item, index) in dangerOccurReport" :key="index" @click="showDangerReportDetail(item, index)" style="cursor: pointer">
								<td>
									<span>[{{ item.siteNm }}]</span>
									<br />
									<span>{{ item.cctvNm }}</span>
								</td>
								<td>{{ item.dangerOccurDtm }}</td>
								<td>{{ item.objectNm }}</td>
								<td>{{ item.targetNm }}</td>
								<td>{{ item.videoDangerActnDvsnNm }}</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="RFlex" style="display: flex; flex-direction: column; padding-right: 3rem">
			<div class="boxBorder" style="width: 415px; height: 580px">
				<div class="titleBox">
					<div style="display: flex; align-items: center">
						<div class="circle"></div>
						발생 위험 유형
						<span style="font-weight: 400; font-size: 14px; color: #666666; margin-left: 10px; padding-top: 5px">
							{{ objectTargetDangerOccurPeriod.start }} ~ {{ objectTargetDangerOccurPeriod.end }}
						</span>
					</div>
				</div>
				<div class="contentBox object-danger-content" style="padding-bottom: 20px">
					<div class="object-danger-scroll">
						<div v-for="(item, index) in objectTargetDangerOccur.objectTargetDangerOccur" :key="index" class="object-danger">
							<div :class="item.classList" class="object-danger-row">
								<div class="name">{{ item.objectNm }}</div>
								<div class="name">{{ item.targetNm }}</div>
								<div class="value">
									<span v-if="item.percent != undefined" class="percent">{{ item.percent }} /&nbsp;</span>
									<span>{{ item.objectTargetDangerCnt }}</span>
								</div>
							</div>
							<table>
								<tr>
									<td :style="item.tdWidth" :class="item.classList"></td>
									<td style="background: #f1f5f8"></td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="boxBorder" style="width: 415px; height: 250px">
				<div class="titleBox today-wwo-title" @click="clickedRedirectionWarnWorkOccur">
					<div style="display: flex; justify-content: space-between">
						<div style="display: flex; align-items: center">
							<div class="circle"></div>
							금일 주의작업
						</div>
						<div style="margin-right: 20px; font-size: 24px; color: #ef5861">
							<span>{{ todayWarnWorkOccurTotal }}</span>
							<span style="font-size: 15px">건</span>
						</div>
					</div>
				</div>
				<div class="contentBox" style="height: 200px">
					<div style="text-align: right; margin-top: -5px">
						<div class="iconBox" style="display: flex; flex-direction: row; margin-top: 13px; justify-content: space-evenly">
							<div v-if="todayWarnWorkOccurTotal == 0" style="margin-top: 25px">
								{{ todayNoneWarnWorkOccur }}
							</div>
							<div v-for="(item, index) in todayWarnWorkOccur" :key="index">
								<div v-if="item.objectId == 'WD'">
									<img src="/images/icons/welding.png" />
									<span>용접</span>
								</div>
								<div v-if="item.objectId == 'FS'">
									<img src="/images/icons/smog.png" />
									<span>연기</span>
								</div>
								<div v-if="item.objectId == 'FR'">
									<img src="/images/icons/spark.png" />
									<span>불꽃</span>
								</div>
								<div v-if="item.objectId == 'SS'">
									<img src="/images/icons/suspended-scaffold.png" />
									<span>달비계</span>
								</div>
								<div v-if="item.objectId == 'WM'">
									<img src="/images/icons/worker.png" />
									<span>작업자</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 위험보고 그리드 클릭시의 모달/ TODO: 모달창 컴포넌트화-->
		<div ref="popupImg" class="modal">
			<!-- Modal content -->
			<div class="modal-content">
				<span @click="popupImgClose" class="close">&times;</span>
				<div style="height: 30px"></div>
				<div class="row" style="display: flex; align-items: center; justify-content: center">
					<div class="col-md-3">
						<div
							style="
								flex: 1;
								overflow-y: auto;
								flex-direction: column;
								height: 720px;
								max-height: 720px;
								border-right: 1px solid #ddd;
								padding-right: 15px;
							"
							left
						>
							<div
								style="position: relative; overflow: hidden"
								v-for="(item, index) in dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs"
								:key="index"
								:class="{ active: index == activePicture }"
								@click="selectPicture(index)"
								:tabindex="index"
								@keydown.up="priviousImage()"
								@keydown.left="priviousImage()"
								@keydown.down="nextImage()"
								@keydown.right="nextImage()"
								photo
							>
								<img :src="dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs[index].imgData" />

								<div>
									<div style="position: absolute; width: 100%; height: 100%; top: 50%; left: 50%; transform: translate(-50%, -50%)"></div>
								</div>
							</div>
						</div>
					</div>
					<div align="center" class="col-md-9">
						<div align="center" right style="flex: 5 1 0%; height: inherit; justify-content: center">
							<img id="popupImg" :src="this.popupImg.imgData" style="width: 910px; max-height: 520px; object-fit: contain; display: block" />
						</div>
						<div align="center" right style="flex: 5 1 0%; margin-left: 15px; justify-content: center">
							<div class="row">
								<div class="mBox col-md-3">
									<div><h4>장소 및 시각</h4></div>
									<div class="border-bottom"></div>
									<div style="margin-top: 10px; margin-left: 20px; font-size: 14px">
										{{ dangerOccurDetailsItem.cctvNm }} - {{ dangerOccurDetailsItem.cctvInstallPlaceCont }}
									</div>
									<div style="margin-top: 10px; margin-left: 20px; font-size: 14px">{{ popupImg.imgSaveDtm }}</div>
								</div>
								<div class="mBox">
									<div><h4>조치 대상</h4></div>
									<div class="border-bottom"></div>
									<div style="display: flex; justify-content: center" :key="forRerendering">
										<div
											class="objects"
											v-for="(objectAndTarget, index) in popupImg.objects"
											:key="index"
											style="display: inline-block; max-width: 50%; margin: 10px"
										>
											<div style="font-size: 17px; font-weight: bold; text-align: center">
												{{ objectAndTarget.objectNm }}
											</div>
											<div style="font-size: 14px; text-align: center" v-for="(target, i) in objectAndTarget.targetNms" :key="i">
												{{ target }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import WeatherInfo from '@/components/WeatherInfo.renew';
import apiIndex from '../../api/index';
import moment from 'moment';

const dangerOccurUrl = apiIndex.dangerOccur;
const mainUrl = apiIndex.main;
const accidentPreUrl = apiIndex.accidentPrevent;

let axiosExtention;

export default {
	components: {
		WeatherInfo,
	},
	computed: {
		// API 호출 통계
		charData() {
			return {
				// 차트 x 축 데이터
				dataSource: [],
				// X축
				categoryAxis: {
					// 데이터를 가져올 필드명 세팅
					field: 'reqDtm',
					// rotation: 화면이 작아졌을 때 세로로 보여줄 거냐..
					// foramt: 값을 보여줄 형태 지정
					// step: 값이 몇 씩 증가하는가..
					labels: { rotation: 'auto', format: 'M/d', step: 1, font: '10px Arial, Helvetica, sans-serif' },
				},
				// Y축
				valueAxis: [
					// format: y축 데이터를 어떻게 표시할 것인가..
					{ labels: { format: '{0:n0}건', font: '11px Arial, Helvetica, sans-serif' } },
				],
				// 툴팁( 마우스 올렸을 때 나오는 조그만 정보 창 )
				tooltip: {
					// 툴팁 표시, 비표시 설정
					visible: true,
					// 툴팁 내용을 어떻게 표시할 것인가..
					template: `#: category #<br/>#: series.name # : #: kendo.toString(value, 'n0') #건`,
				},
				// 차트 원본 데이터(json 배열) 세팅
				series: [],
			};
		},
	},
	created() {
		axiosExtention = this.$jarvisExtention.axiosExtention;
		this.menuGubun = process.env.VUE_APP_MENU_GUBUN;
		this.objectTargetDangerOccurPeriod.start = moment().add(-6, 'day').format('YYYY-MM-DD');
		this.objectTargetDangerOccurPeriod.end = moment().format('YYYY-MM-DD');
		sessionStorage.setItem('viewNm', 'Dashboard');
	},
	mounted() {
		// 메인 대시보드에서 content 관련 css에 의해 공백 생기는것 제거
		let contentRoot = document.getElementsByClassName('content');
		if (contentRoot.length > 0) {
			contentRoot[0].classList.remove('content');
		}

		this.getMainInfo();
		// 최신 공지사항 불러오기
		this.loadRecentlyNotice();

		// 위험 보고 항목 가져오기
		this.loadDangerOccurReport(); // 딜레이 없이 바로 표시하기 위함
		setInterval(() => {
			this.loadDangerOccurReport();
		}, 60000); // 1분 마다 갱신

		this.$axios // 조치대기, 검토대기, 완료, 금일마감, 마감지연
			.post(mainUrl.inqCurrentAccidentPrevent, {})
			.then(r => {
				this.currentAccidentPreventItem = JSON.parse(JSON.stringify(r.data));
			})
			.catch(axiosExtention.buildErrorHandler());

		this.loadDayChart();
		this.popupImgClose();
	},
	data: () => ({
		pageParam: {},
		userInfo: '',
		loginUserCompanyNm: '',
		loginUserSiteNm: '',
		loginUserNm: '',
		// 최근 재해조치 요청 목록
		latestInfoItem: {
			0: { accdtPrvtnPgssCdNm: '', path: '/images/noimage.png', reqDtm: '' },
			1: { accdtPrvtnPgssCdNm: '', path: '/images/noimage.png', reqDtm: '' },
			2: { accdtPrvtnPgssCdNm: '', path: '/images/noimage.png', reqDtm: '' },
		},
		// 현재 선택된 재해조치 요청
		currentInfoItem: {
			objectId: '',
			targetId: '',
			reqUserId: '',
			actnUserId: '',
			actnDtm: '',
			path: '/images/noimage.png',
		},
		warnWorkOccurs: '',
		currentWarnWorkDangerOccur: {
			dangerOccurCnt: '0',
			warnWorkOccurCnt: '0',
			takingActionCnt: '0',
		},
		currentAccidentPreventItem: {
			reqCnt: '0',
			reviewCnt: '0',
			todayDelayCnt: '0',
			actnCnt: '0',
			accumulatedCnt: '0',
			delayCnt: '0',
		},
		accidentPreventItem: {},
		chartJson: [],
		todayDate: '', // 현재 날짜
		chartClickClass: ['btn-primary', 'btn-default', 'btn-default'],
		// 대상물 위험유형
		objectTargetDangerOccur: {},
		objectTargetDangerOccurPeriod: { start: '', end: '' },
		// 금일 주의작업
		todayNoneWarnWorkOccur: '금일 발생한 주의 작업이 없습니다',
		todayWarnWorkOccurTotal: 0,
		todayWarnWorkOccur: [],
		// 위험 보고 테이블 정보
		dangerOccurTable: {
			siteCctvNm: 'CCTV 구역',
			dangerOccurDtm: '발생시간',
			objectNm: '대상물',
			targetNm: '조치사항',
			videoDangerActnDvsnNm: '상태',
		},
		// 위험 보고 리스트
		dangerOccurReport: [],
		// 실시간 이벤트
		dangerOccurRealtime: null,
		dangerOccurRealtimeImgSrc: '',
		nonDangerOccurResultStr: '현재 위험 발생한 이벤트가 없습니다',
		// 최신 공지사항
		noticeInfo: {},
		dangerOccurDetailsItem: {},
		popupImg: {},
		activePicture: 0,
		isImagePoppedUp: true,
		dangerZoneAtSequenceMap: {},
		sequenceMapForDetail: {},
		outFramesDetailData: {},
		menuGubun: '',
		forRerendering: 0,
	}),
	watch: {
		// 팝업 모달이 생겨났을 때, 해당 이미지에 포커스를 두도록(키보드로 이미지를 바로 이동시킬 수 있게 하기 위함)
		isImagePoppedUp() {
			if (this.isImagePoppedUp == true) {
				this.$nextTick(function () {
					let selectedImage = document.getElementsByClassName('active')[0];
					selectedImage.focus();
				});
			}
		},
		// 활성화된 이미지가 변경됐을 때 이미지 데이터를 변경하기 위함
		activePicture() {
			this.popupImg = this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs[this.activePicture];
		},
	},
	methods: {
		curEventFrames() {
			let dangerOccurIndex = this.dangerOccurReport.findIndex(e => e.videoDangerActnDvsnNm == '위험 발생');
			this.showDangerReportDetail(this.dangerOccurReport[dangerOccurIndex], dangerOccurIndex);
		},
		getMainInfo() {
			this.$axios
				.post(mainUrl.mainInfo, {})
				.then(response => {
					if (response.data) {
						if (response.data.userInfo) {
							this.userInfo = response.data.userInfo;
							this.loginUserCompanyNm = this.userInfo.companyNm;
							this.loginUserSiteNm = this.userInfo.siteNm;
							this.loginUserNm = this.userInfo.userNm;
						}

						if (response.data.warnWorkDangerOccur) {
							this.currentWarnWorkDangerOccur = response.data.warnWorkDangerOccur;
						}

						if (response.data.objectTargetDangerOccur) {
							this.objectTargetDangerOccur = response.data.objectTargetDangerOccur;
							let total = this.objectTargetDangerOccur.objectTargetDangerOccurTotal;
							let list = this.objectTargetDangerOccur.objectTargetDangerOccur;
							if (total != 0) {
								let value = 100 / total;
								// 항목별 퍼센트 세팅(소수점 두자리)
								for (let i = 0; i < list.length; i++) {
									let percent = Math.round(list[i].objectTargetDangerCnt * value * 100) / 100;
									list[i].percent = percent + '%';
									list[i].tdWidth = 'width: ' + percent + '%';
									if (percent > 30) list[i].classList = 'red';
									else if (percent > 0) list[i].classList = 'blue';
									else list[i].classList = '';
								}
							}
						}

						if (response.data.todayWarnWorkOccur) {
							let data = response.data.todayWarnWorkOccur;

							let todayWarnWorkOccur = [];
							for (let i = 0; i < data.length; i++) {
								if (data[i].objectId == 'total') this.todayWarnWorkOccurTotal = data[i].warnWorkOccurCnt;
								else todayWarnWorkOccur.push(data[i]);
							}
							this.todayWarnWorkOccur = todayWarnWorkOccur;
						}
					}
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		changeChartClickClass(index) {
			let changeList = [];
			this.chartClickClass.forEach((e, idx) => {
				if (idx === index) {
					changeList.push('btn-primary');
				} else {
					changeList.push('btn-default');
				}
			});
			this.chartClickClass = changeList;
		},
		// 차트 그리기
		drawChart() {
			$('#lineChart').kendoChart({
				dataSource: this.charData.dataSource,
				categoryAxis: this.charData.categoryAxis,
				valueAxis: this.charData.valueAxis,
				series: this.charData.series,
				legend: {
					position: 'bottom',
				},
				tooltip: this.charData.tooltip,
			});
		},
		// 월/주/일 데이터 로드 후 차트에서 보여주기 위해 데이터 세팅
		createChartData(data) {
			let reqCntList = [];
			let reviewCntList = [];
			let actnCntList = [];
			let delayCntList = [];

			// 데이터 비우고 다시 세팅
			this.charData.dataSource = [];
			data.forEach(d => {
				this.charData.dataSource.push({ reqDtm: d.reqDtm });
				reqCntList.push(d.reqCnt);
				reviewCntList.push(d.reviewCnt);
				actnCntList.push(d.actnCnt);
				delayCntList.push(d.delayCnt);
			});

			let reqCnt = {
				type: 'line',
				data: reqCntList,
				name: '조치대기',
				color: '#034EA2',
			};

			let reviewCnt = {
				type: 'line',
				data: reviewCntList,
				name: '검토대기',
				color: '#5BC0DE',
			};

			let actnCnt = {
				type: 'line',
				data: actnCntList,
				name: '완료',
				color: '#00B050',
			};

			let delayCnt = {
				type: 'line',
				data: delayCntList,
				name: '마감지연',
				color: '#FF0000',
			};

			// 데이터 비우고 다시 세팅
			this.charData.series = [];
			this.charData.series.push(reqCnt);
			this.charData.series.push(reviewCnt);
			this.charData.series.push(actnCnt);
			this.charData.series.push(delayCnt);

			this.drawChart();
		},
		// 일 그래프
		loadDayChart() {
			this.$axios
				.post(mainUrl.inqDayChart, {})
				.then(response => {
					this.changeChartClickClass(0);
					this.chartJson = response.data;
					this.createChartData(response.data);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 주 그래프
		loadWeekChart() {
			this.$axios
				.post(mainUrl.inqWeekChart, {})
				.then(response => {
					this.changeChartClickClass(1);
					this.chartJson = response.data;
					this.createChartData(response.data);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 월 그래프
		loadMonthChart() {
			this.$axios
				.post(mainUrl.inqMonthChart, {})
				.then(response => {
					this.changeChartClickClass(2);
					this.chartJson = response.data;
					this.createChartData(response.data);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 재해조치 요청 목록 클릭
		latestDetail(index) {
			// 현재 선택된 값을 바꿔줌
			this.currentInfoItem = this.latestInfoItem[index];
		},
		clickedCnt(kind) {
			let param = { kind: kind };
			// 권한 체크
			const accidentPre = $('a[data-desc="재해예방조치"]');
			if (accidentPre && accidentPre.length > 0) {
				// 기존 pageParam 내용 불러오기
				this.$axios
					.post(accidentPreUrl.viewAccidentPrevent, new URLSearchParams(param))
					.then(
						function (r) {
							if (r.data.token) delete r.data.token;
							sessionStorage.setItem('accidentPreventPageParam', JSON.stringify(r.data));
							location.href = '/accident-prevent';
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			}
		},
		clickedRedirectionDashboard() {
			window.open('/dashboard');
		},
		// 위험발생 n건 클릭 시 화면 이동
		clickedRedirectionDangerOccur() {
			const dangerOccur = $('a[data-desc="위험발생이력"]');

			if (dangerOccur && dangerOccur.length > 0) {
				dangerOccur[0].click();
			}
		},
		// 금일 주의작업 or 주의발생 n건 클릭 시 화면 이동
		clickedRedirectionWarnWorkOccur() {
			const warnWorkOccur = $('a[data-desc="주의작업이력"]');

			if (warnWorkOccur && warnWorkOccur.length > 0) {
				warnWorkOccur[0].click();
			}
		},
		// 조치중 클릭 시 화면 이동
		clickedRedirectionDangerOccurTakingAction() {
			const dangerOccur = $('a[data-desc="위험발생이력"]');
			if (dangerOccur && dangerOccur.length > 0) {
				this.$axios
					.post(dangerOccurUrl.viewDangerOccur, new URLSearchParams({ videoDangerActnDvsnCd: '03' }))
					.then(
						function (r) {
							if (r.data.token) delete r.data.token;
							sessionStorage.setItem('dangerOccurPageParam', JSON.stringify(r.data));
							location.href = r.data.viewUrl;
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			}
		},
		loadDangerOccurReport() {
			this.$axios
				.post(mainUrl.inqDangerOccurReport, {})
				.then(async r => {
					let data = r.data;

					let commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));
					// topmenu의 commonCodeList 불러오는 함수가 Main보다 늦게 뜨면 오류 발생하기 때문에 없을 경우 불러옴
					if (!commonCodeList || commonCodeList.length == 0) {
						await this.$axios
							.post(apiIndex.commCode.inqCommCodeAllList, {})
							.then(
								function (r) {
									commonCodeList = r.data;
								}.bind(this),
							)
							.catch(axiosExtention.buildErrorHandler());
					}

					let videoDangerActnDvsnCdOption = commonCodeList.filter(item => item.grpCd == 'video_danger_actn_dvsn_cd');

					// 위험 보고 리스트
					let dangerOccurReportList = data.dangerOccurReportList;
					if (dangerOccurReportList && dangerOccurReportList.length > 0) {
						for (let i = 0; i < dangerOccurReportList.length; i++) {
							dangerOccurReportList[i].videoDangerActnDvsnNm = videoDangerActnDvsnCdOption.filter(
								item => item.value == dangerOccurReportList[i].videoDangerActnDvsnCd,
							)[0].text;
						}

						this.dangerOccurReport = dangerOccurReportList;
					}

					// 실시간 이벤트
					this.dangerOccurRealtime = data.dangerOccurRealtime;

					if (this.dangerOccurRealtime != null) {
						this.$set(this.dangerOccurRealtime, 'type', 'event');
						this.dangerOccurRealtime.videoDangerActnDvsnNm = videoDangerActnDvsnCdOption.filter(
							item => item.value == this.dangerOccurRealtime.videoDangerActnDvsnCd,
						)[0].text;

						this.getRealtimeEventImg(this.dangerOccurRealtime);
					} else {
						this.dangerOccurRealtime = {};
						this.$set(this.dangerOccurRealtime, 'type', 'nonEvent');
					}
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		getRealtimeEventImg(item) {
			this.$axios
				.post(mainUrl.imgRealtimeEvent, item)
				.then(r => {
					this.dangerOccurRealtimeImgSrc = 'data:image/jpg;base64,' + r.data.imgVO.imgByte;
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		loadRecentlyNotice() {
			this.$axios
				.post(mainUrl.inqRecentlyNotice, {})
				.then(r => {
					this.noticeInfo = r.data;
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		showNoticeDetail(index) {
			if (!this.noticeInfo[index] || !this.noticeInfo[index].noticeId) {
				return alert('공지사항 정보가 올바르지 않습니다');
			}

			// 팝업창 가로/세로크기
			let w = 600;
			let h = 600;

			let url = 'recently-notice?notice_id=' + this.noticeInfo[index].noticeId;
			let title = 'recently-notice-popup';

			var left = screen.width / 2 - w / 2;
			var top = screen.height / 2 - h / 2;
			return window.open(
				url,
				title,
				'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
					w +
					', height=' +
					h +
					', top=' +
					top +
					', left=' +
					left,
			);
		},
		showDangerReportDetail(item) {
			this.dangerOccurDetailsItem = {};
			this.dangerOccurObjectsAtSequenceMap = {};
			this.popupImg = {};
			this.$axios
				.post(dangerOccurUrl.inqOneDangerOccur, {
					siteId: item.siteId,
					cctvId: item.cctvId,
					objectId: item.objectId,
					targetId: item.targetId,
					dangerOccurObjectSeq: item.dangerOccurObjectSeq,
					strtFrameImgSeq: item.strtFrameImgSeq,
					endFrameImgSeq: item.endFrameImgSeq,
					dangerOccurDtm: item.dangerOccurDtm,
				})
				.then(
					function (response) {
						//그리드에서 row를 선택했을 때 detailsItem 세팅용
						this.setDetailsItem(response.data, item);
						//이미지 데이터 세팅
						this.setAllFramesToImageData();
						//  맵에 시퀀스를 키로, 대상물과 조치사항/위험지역명을 세팅
						// this.setSequenceMap();
						// // 시퀀스맵을 활용해서 대상물과 조치사항이 있는 시퀀스에 대상물과 조치사항을 세팅
						// this.setObjectsAndTargetsToDetailsItem();

						// img 클릭 시 popup
						this.$nextTick(function () {
							this.popupImg = this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs[this.activePicture];
							// this.activePicture = this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs.findIndex(e => e.imgSaveDtm == item.dangerOccurDtm);
							this.$refs.popupImg.style.display = 'block';
							this.isImagePoppedUp = true;
							this.selectPicture(this.activePicture);
						});
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		//모달창에서 키 다운으로 이전 이미지로 이동
		priviousImage() {
			if (this.activePicture > 0) {
				this.activePicture = this.activePicture - 1;
				this.selectPicture(this.activePicture);
			}
		},
		//모달창에서 키 다운으로 다음 이미지로 이동
		nextImage() {
			if (this.activePicture < this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs.length - 1) {
				this.activePicture = this.activePicture + 1;
				this.selectPicture(this.activePicture);
			}
		},
		//모달창에서 이미지를 선택했을 때
		selectPicture(index) {
			if (index < 0 || index >= this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs.length) {
				return;
			}
			this.activePicture = index;
			let lastIndexOfFrame = this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs.length - 1;
			let endFrameImgSeq = this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs[lastIndexOfFrame].frameImgSeq;
			this.$axios
				.post(dangerOccurUrl.inqImgDetailsInfo, {
					siteId: this.dangerOccurDetailsItem.siteId,
					cctvId: this.dangerOccurDetailsItem.cctvId,
					frameImgSeq: this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs[this.activePicture].frameImgSeq,
					endFrameImgSeq: endFrameImgSeq,
				})
				.then(
					function (r) {
						let objects = [];
						if (r.data != '') {
							r.data.forEach(e => {
								let objectAndTarget = {};
								this.$set(objectAndTarget, 'objectNm', e.objectNm);
								this.$set(objectAndTarget, 'targetNms', e.targetNms);
								objects.push(objectAndTarget);
							});
						}
						this.popupImg.objects = objects;
						console.log(this.popupImg.objects);
						this.forRerendering += 1;
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());

			let distance = (index - 10) * 10;
			$('.modal-content [left]').animate({ scrollTop: index * 130 - 170 + distance }, 50);
		},
		popupImgClose() {
			// img popup 닫기
			this.$refs.popupImg.style.display = 'none';
			this.isImagePoppedUp = false;
			this.activePicture = 0;
		},
		setDetailsItem(data, selectedRowItem) {
			this.dangerOccurDetailsItem = $.extend(true, {}, data);
			this.dangerOccurDetailsItem.dangerOccurDtm = selectedRowItem.dangerOccurDtm;
			this.dangerOccurDetailsItem.objectId = selectedRowItem.objectId;
			this.dangerOccurDetailsItem.targetId = selectedRowItem.targetId;
			this.dangerOccurDetailsItem.dangerOccurObjectSeq = selectedRowItem.dangerOccurObjectSeq;
			this.dangerOccurDetailsItem.videoDangerActnDvsnCd = selectedRowItem.videoDangerActnDvsnCd;
			this.dangerOccurDetailsItem.actnReasonCont = selectedRowItem.actnReasonCont;
			this.dangerOccurDetailsItem.strtFrameImgSeq = selectedRowItem.strtFrameImgSeq;
			this.dangerOccurDetailsItem.obejctNm = selectedRowItem.obejctNm;
			this.dangerOccurDetailsItem.targetNm = selectedRowItem.targetNm;
			this.dangerOccurDetailsItem.cctvNm = selectedRowItem.cctvNm;
			this.dangerOccurDetailsItem.cctvInstallPlaceCont = selectedRowItem.cctvInstallPlaceCont;
		},
		//이미지 데이터 세팅
		setAllFramesToImageData() {
			this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs.forEach(e => {
				this.$axios.post(dangerOccurUrl.imgOneDangerOccur, { fileName: e.imgSaveFileNm }).then(response => {
					e.imgData = 'data:image/jpg;base64,' + response.data.imgByte;
				});
			});
		},
		//타겟을 중복없이 세팅
		setTargetNmsToObjectWithoutDuplication(vo, targetMap) {
			let filter = targetMap[vo.objectNm].filter(e => e == vo.targetNm);
			if (vo.targetNm != null && filter != undefined && filter.length == 0) {
				targetMap[vo.objectNm].push(vo.targetNm);
			}
		},
		// 맵에 시퀀스를 키로, 대상물과 조치사항/ 위험지역을 세팅
		setSequenceMap() {
			this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs.forEach(imgInfo => {
				//각 프레임의 이미지 정보로 outFramesDetailData에 값을 세팅. 대상물 및 위험지역이 있는 시퀀스만 추려내는 작업
				//Refactor: 굳이 이 과정이 없어도 될 것 같다.
				this.makeOutFramesDetailData(imgInfo);
				//위험지역명을 저장할 배열
				var dangerZoneNms = [];
				if (this.outFramesDetailData != undefined && this.outFramesDetailData[imgInfo.frameImgSeq] != undefined) {
					this.outFramesDetailData[imgInfo.frameImgSeq].forEach(element => {
						//각 시퀀스에 해당하는 대상물(+조치대상)들을 sequenceMap에 세팅
						this.setObjectNmsToSequenceMapForDetail(imgInfo, element);
						//해당 시퀀스에 위험지역명이 있으면
						if (element.dangerZoneNm != null) {
							//위험지역명저장
							dangerZoneNms.push(element.dangerZoneNm);
						}
					});
					//sequenceMapForDetail의 해당 시퀀스에 위험지역명 추가
					this.$set(this.sequenceMapForDetail[imgInfo.frameImgSeq], 'dangerZoneNms', Array.from(new Set(dangerZoneNms)));
				}
			});
		},
		//sequenceMapForDetail의 해당 시퀀스에 맞는 objectNms를 세팅하는 작업
		//Refactor: Set을 활용하면 좀 더 단순하게 할 수 있는 방법이 있지 않을까?
		setObjectNmsToSequenceMapForDetail(imgInfo, outFramesDetailDataElement) {
			if (this.sequenceMapForDetail[imgInfo.frameImgSeq] == undefined) {
				this.$set(this.sequenceMapForDetail, imgInfo.frameImgSeq, {});
			}
			if (this.sequenceMapForDetail[imgInfo.frameImgSeq].objectNms == undefined) {
				this.$set(this.sequenceMapForDetail[imgInfo.frameImgSeq], 'objectNms', new Object());
			}
			if (this.sequenceMapForDetail[imgInfo.frameImgSeq]['objectNms'][outFramesDetailDataElement.objectNm] == undefined) {
				this.$set(
					this.sequenceMapForDetail[imgInfo.frameImgSeq]['objectNms'],
					outFramesDetailDataElement.objectNm,
					new Array(outFramesDetailDataElement.targetNm),
				);
			} else {
				this.setObjectInfosWithoutDuplication(outFramesDetailDataElement, this.sequenceMapForDetail[imgInfo.frameImgSeq]['objectNms']);
			}
		},
		// //타겟을 중복없이 세팅
		setObjectInfosWithoutDuplication(vo, targetMap) {
			if (targetMap[vo.objectNm] == undefined || targetMap[vo.objectNm][0] == null) {
				return;
			}
			let filter = targetMap[vo.objectNm].filter(e => e == vo['targetNm']);
			if (vo['targetNm'] != null && filter != undefined && filter.length == 0) {
				targetMap[vo.objectNm].push(vo['targetNm']);
			}
		},
		setObjectsAndTargetsToDetailsItem() {
			this.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs.forEach(e => {
				if (this.sequenceMapForDetail[e.frameImgSeq] != undefined) {
					if (this.sequenceMapForDetail[e.frameImgSeq].objectNms != undefined) {
						this.$set(e, 'objects', this.sequenceMapForDetail[e.frameImgSeq].objectNms);
					}
					if (this.sequenceMapForDetail[e.frameImgSeq].dangerZoneNms.length != 0) {
						this.$set(e, 'dangerZoneNms', this.sequenceMapForDetail[e.frameImgSeq].dangerZoneNms);
					}
				}
			});
		},
		//각 프레임의 이미지 정보로 outFramesDetailData에 값을 세팅. 대상물 및 위험지역이 있는 시퀀스만 추려내는 작업
		makeOutFramesDetailData(imgInfo) {
			let frameImgSeqData = this.dangerOccurDetailsItem.inqDangerOccurFrameImgDetailOutVOs.filter(e => e.frameImgSeq == imgInfo.frameImgSeq);
			if (frameImgSeqData.length != 0) {
				this.$set(this.outFramesDetailData, imgInfo.frameImgSeq, frameImgSeqData);
			}
		},
	},
};
</script>

<style scoped>
@font-face {
	font-family: 'Pretendard';
	src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
body .content-wrapper {
	height: 100% !important;
}
body .content {
	padding: 0 !important;
}
#bd > div {
	height: 90px;
	padding-top: 5px;
}
#rootVM {
	height: 93vh;
	margin-left: 16vw;
	padding-left: 4rem;
	overflow-y: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;

	font-family: 'Pretendard';
	font-style: normal;
	color: #333333;
	margin-bottom: 0;
	background-color: #ebeff2;
}
#rootVM::-webkit-scrollbar {
	display: none;
}
#rootVM > div {
	font-size: 1.4em;
	width: 100%;
	display: flex;
}
.boxBorder {
	background: rgba(255, 255, 255, 0.6);
	box-shadow: 0px 2px 20px rgba(205, 213, 219, 0.1);
	border-radius: 15px;

	margin-right: 18px;
	margin-bottom: 18px;
}
.RFlex > .boxBorder {
	margin-right: 0 !important;
}

.boxBorder > .titleBox {
	height: 48px;
	padding: 10px 0 0 10px;
	font-weight: bold;
	/* box-shadow: 0px 2px 20px rgba(205, 213, 219, 0.1); */
	border-radius: 15px;
}
.boxBorder > .contentBox {
	background: rgba(255, 255, 255);
	border-radius: 15px;
	box-shadow: 0px 2px 20px rgba(205, 213, 219, 0.1);
	padding: 15px;
}
table {
	width: 100%;
	/* border: 1px solid #dfdfdf; */
	border-collapse: collapse;
}
th {
	height: 35px;
	background-color: #ebeff2;
	font-size: 0.8em;
	font-weight: bold;
}
td {
	height: 32px;
	font-size: 0.7em;
}
th,
td {
	text-align: center;
}

.circle {
	margin-right: 10px;
	display: inline-block;
	width: 20px;
	height: 20px;
	border: 2px solid #9bd29d;
	border-radius: 50%;
}

[danger-occur-table] > tr > td {
	border-bottom: 1px solid #e4e4e9;
	color: #666666;
}
.iconBox > div > div {
	display: flex;
	flex-direction: column;
	border: 1px solid #37474f;
	text-align: center;
}
.iconBox > div > div > img {
	width: 70px;
}
.iconBox > div > div > span {
	font-size: 13px;
	padding: 3px;
	color: #37474f;
}

#rootVM [process] > div > div {
	flex: 1;
	border-right: 1px solid #e4e4e9;
	display: flex;
	flex-direction: column;
}
#rootVM [process] > div > div:last-child {
	border: none;
}
#rootVM [process] [left] > div:last-child {
	border: none;
}

#rootVM [process] > div {
	align-items: center;
}
#rootVM [process] > div > div > div {
	display: flex;
	align-items: center;
}
#rootVM [process] > div > div > div:first-child {
	font-weight: 400;
	font-size: 15px;
	line-height: 21px;
}
.process-info-title {
	display: flex;
	flex-direction: column;
}

.process-info {
	display: flex;
	justify-content: flex-end;
}
.process-img {
	width: 25px;
	height: 25px;
	margin-right: 0px;
}
.process-suffix {
	font-size: 14px;
	color: #333333;
	margin-left: 5px;
	margin-right: 20px;
	margin-top: 10px;
}

.process-count {
	font-weight: 600;
	font-size: 30px;
}

#lineChart {
	width: 100%;
	height: 200px;
}

/* 노트북 사이즈 */
@media only screen and (max-width: 1780px) {
	/* #rootVM [process] > div > div > div:last-child { margin-top:5px; }
  #rootVM [process] > div { max-height:130px; }
  #rootVM [bottom] [current] { height:200px; max-height:150px; min-height:150px; } */
}

.object-danger-content {
	height: 92%;
	/* overflow-y: scroll; */
}
.object-danger {
	width: 100%;
	display: block;
	font-size: 13.5px !important;
}
.object-danger > .red {
	color: #ef5861;
}
.object-danger > .blue {
	color: #3979b8;
}
.object-danger > table {
	margin-bottom: 3px;
}
.object-danger > table > tr > td {
	height: 20px;
	background-color: rgb(240, 240, 240);
}
.object-danger > table > tr > .red {
	background-color: #ef5861;
}
.object-danger > table > tr > .blue {
	background-color: #3979b8;
}
.object-danger-row {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
}
.object-danger-row > div {
	overflow: hidden;
	white-space: nowrap;
	font-weight: bold;
	font-size: 1.25rem;
}
.object-danger-row > .name {
	width: 40%;
}
.object-danger-row > .value {
	width: 40%;
	text-align: right;
}
.object-danger-row > .value > .percent {
	font-weight: lighter;
	font-size: 0.8em;
	color: black;
}
.object-danger-scroll {
	height: 100%;
	overflow-y: scroll;
	padding-right: 7px;
}
.object-danger-scroll::-webkit-scrollbar {
	width: 10px;
	background-color: #fafafa;
}
.object-danger-scroll::-webkit-scrollbar-thumb {
	background: #d9d9d9;
}

.today-wwo-title {
	cursor: pointer;
}

[no-result] {
	width: 110px;
	margin: 60px;
}
.rt-dvsn {
	/* background: #d15661; */
	/* color: #fff; */
	color: #ef5861;
	font-weight: 600;
	font-size: 18px;
	margin-top: 10px;
	/* width: fit-content; */
	/* margin-left: 2px; */
	/* padding: 2px 5px 0 5px; */
	/* border-radius: 5px; */
}
.rt-time {
	color: #5089a4;
	font-weight: 400;
	font-size: 24px;
	margin-top: -3px;
	margin-bottom: 30px;
}
.rt-row {
	display: inline-flex;
}
.rt-row-tt {
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	padding-top: 3px;
	width: 60px;
	margin-bottom: 9px;
	margin-right: 10px;
}
.rt-row-ct {
	font-size: 16px;
	width: 200px;
}
[notice] > div {
	display: flex;
	width: 1074px;
	height: 30px;
	padding: 0px;
	font-size: 15px;
	cursor: pointer;
}
[notice] > div > div:not(:first-child) {
	margin-top: 2px;
}
[notice] > div > div {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
[notice] > div .nt-guide {
	background: #2367b2;
	color: rgb(255, 255, 255);
	font-size: 15px;
	width: 15%;
	padding: 2px 10px 0 10px;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	text-align: center;
}

*:focus {
	outline: none;
}
.modal-content img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.modal-content [photo] {
	cursor: pointer;
	border-radius: 4px;
	overflow: hidden;
}
.modal-content [photo] img {
	min-height: 120px;
	height: 120px;
}
.modal-content [photo].active {
	border: 5px solid #1878f3;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
}

@media (min-width: 500px) and (max-width: 1800px) {
	.modal-content {
		background-color: #fefefe;
		margin: 1% auto;
		padding: 20px;
		border: 1px solid #888;
		width: 80%;
	}
	.modal-content [left] {
		padding: 0 15px;
		height: 85vh !important;
	}
	#popupImg {
		width: 55vw !important;
		max-height: 55vh !important;
	}
}

@media (min-width: 1801px) {
	.modal-content {
		background-color: #fefefe;
		margin: 4% auto;
		padding: 20px;
		border: 1px solid #888;
		width: 1300px;
		height: 800px;
	}
	.modal-content [left] {
		padding: 0 15px;
	}
}

.modal-content [left] [photo] {
	margin-top: 20px;
}
.modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 99; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	background-color: rgb(0, 0, 0); /*Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* //Black w/ opacity */
}

/* The Close Button */
.close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}
.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}
.border {
	border-radius: 3px;
	border: 1px solid #ddd;
	float: left;
}
.border-bottom {
	width: 100%;
	margin: auto;
	border-bottom: 1px solid #ddd !important;
}
.rhtBox {
	/* height: 135px; */
	min-height: 135px;
	height: auto;
}
.rhtBox > div:first-child {
	/* background: #f9f9f9; */
	background: #e0e0e0;
}
h3 {
	font-weight: bold !important;
	margin: 0 !important;
	padding: 10px 10px 10px 15px;
}
.mBox {
	/* height: 135px; */
	height: 170px;
	border-radius: 3px;
	border: 1px solid #ddd;
	/* margin-bottom: 50px; */
	margin-bottom: 30px;
	margin-top: 30px;
}
.mBox > div > h4 {
	background: #e0e0e0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.objects > div:first-child {
	font-size: 17px !important;
	font-weight: bold !important;
}

.btn-primary {
	background-color: #2367b2 !important;
	border-color: #2367b2 !important;
}
</style>
